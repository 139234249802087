import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from "styled-components"
import tw from "tailwind.macro"

const Header = styled.header`
  ${tw`mt-24`};
`

const Heading = styled.h1`
  ${tw`mb-2 font-black text-4xl leading-tight`};
`

const Time = styled.time`
  ${tw`block text-base font-mono text-grey-dark leading-normal`}
`


const StyledLink = styled(Link)`${tw`no-underline text-purple-darkest hover:text-purple-darkest hover:underline`}`
const Flex = styled.div`${tw`flex items-center mb-4`}`
const Tag = styled(Link)`${tw`ml-2 font-normal no-underline text-grey-dark hover:text-red hover:underline`}`
const Intro = styled.div`${tw`text-xl leading-normal mb-0`}`
const Readmore = styled(Link)`${tw`no-underline text-red font-normal hover:text-red hover:underline`}`

export default ({ article }) => (
  <>
    <Header>
        <Heading>
            <StyledLink to={`/articles/${article.slug}`}>{article.title}</StyledLink>
        </Heading>
        <Flex>
            <Time datetime="2015-01-30 13:00" className="text-grey-dark">{article.publishDate} in </Time>
            <div>
                {article.tags && article.tags.map(tag => (
                    <Tag to={`/tags/${tag}`} key={tag}>
                        #{tag}
                    </Tag>
                ))}
            </div>
        </Flex>
    </Header>

    {/* <Img className="mb-4 rounded-sm relative z-0" alt="" fluid={article.heroImage.fluid} /> */}

    <section>
        <Intro dangerouslySetInnerHTML={{ __html: article.description.childMarkdownRemark.html }} />
        <Readmore to={`/articles/${article.slug}`}>Read this article &rarr;</Readmore>
    </section>
  </>
)
