import React from 'react'
import { Link } from 'gatsby'
import styled from "styled-components"
import tw from "tailwind.macro"

const Header = styled.header`
  ${tw`w-full p-8 bg-purple text-blue`};
`

const Nav = styled.nav`
  ${tw`px-4 flex flex-row-reverse items-center`};
`

const Heading = styled.h1`
  ${tw`ml-auto text-2xl text-white`};
`

const StyledLink = styled(Link)`
  ${tw`text-xl mr-6 text-white font-bold leading-tight no-underline hover:text-red hover:no-underline`}
`

export default () => (
    <Header>
        <Nav>
            <Heading>Ben Counsell</Heading>

            {/* <ul className="flex list-reset text-serif m-0 border-l-2 border-red pl-4">
                <li>
                    <StyledLink to="/tags/css">#css</StyledLink>
                </li>
                <li>
                    <StyledLink to="/tags/js">#js</StyledLink>
                </li>
                <li>
                    <StyledLink to="/tags/grid">#grid</StyledLink>
                </li>
            </ul> */}

            <ul className="flex list-reset m-0">
                <li>
                    <StyledLink to="/" activeClassName="active">Articles</StyledLink>
                </li>
                <li>
                    <StyledLink to="/about/" activeClassName="active">About</StyledLink>
                </li>
            </ul>
        </Nav>
    </Header>
)
