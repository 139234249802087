import React from 'react'
import { Link } from 'gatsby'
import Container from './container'
import Navigation from './navigation'
import styled from "styled-components"
import tw from "tailwind.macro"
import Helmet from 'react-helmet'
import Footer from './footer'

const Section = styled.section`
    ${tw`max-w-md mx-auto`};
`

const Main = styled.main`
    ${tw`px-12`};
`

class Template extends React.Component {
    render() {
        const { location, children } = this.props
        let header

        let rootPath = `/`
        if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
            rootPath = __PATH_PREFIX__ + `/`
        }

        return (
            <Container>
                <Helmet>
                    <html lang="en" />
                    <body className="bg-white antialiased text-lg font-sans text-grey-darkest leading-normal" />

                </Helmet>

                <Navigation />

                <Main>
                    {children}
                </Main>

                <Footer />
            </Container>
        )
    }
}

export default Template
