import React from 'react'
import styled from "styled-components"
import tw from "tailwind.macro"

const Grid = styled.div`
  ${tw`max-w-2xl mx-auto`};
`

export default ({ children }) => (
    <Grid className="grid">{children}</Grid>
)