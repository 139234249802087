import React from 'react'
import { Link } from 'gatsby'
import styled from "styled-components"
import tw from "tailwind.macro"

const Footer = styled.footer`
  ${tw`max-w-lg mx-12 py-12 mt-24 border-t-2 border-grey-lighter`};
`

const Wrapper = styled.section`
  ${tw`px-4`};
`

const Para = styled.p`
  ${tw`text-lg font-mono mb-4`};
`

const StyledLink = styled(Link)`
  ${tw`text-lg mr-6 font-bold text-red no-underline hover:text-purple hover:underline`}
  `

const Twitter = styled.a`
  ${tw`text-lg mr-6 font-bold text-red no-underline hover:text-purple hover:underline`}
`

export default () => (
    <Footer>
        <Para>Ben Counsell is a web developer based in Stockholm, Sweden.</Para>
        <ul className="flex list-reset text-serif m-0">
            <li>
                <StyledLink to="/">Articles</StyledLink>
            </li>
            <li>
                <StyledLink to="/about">About</StyledLink>
            </li>
            <li>
                <Twitter href="https://twitter.com/bencounsell" rel="me">@bencounsell</Twitter>
            </li>
        </ul>
    </Footer>
)
