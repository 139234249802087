import React from 'react'
import styled from "styled-components"
import tw from "tailwind.macro"

const Container = styled.div`
  ${tw`font-sans`};
`

export default ({ children }) => (
  <Container>{children}</Container>
)
