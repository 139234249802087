import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import Grid from '../components/grid'

import styled from "styled-components"
import tw from "tailwind.macro"

const Heading = styled.h1`
  ${tw`text-4xl mb-2 leading-tight font-black text-black`};
`

const Paragraph = styled.p`
  ${tw`text-xl text-grey-darkest leading-normal`};
`

const Body = styled.section`
  ${tw`text-lg mb-8`}
`

const Anchor = styled.a`
  ${tw`text-lg mb-8`}
`



class About extends React.Component {
    render() {
        const siteTitle = get(this, 'props.data.site.siteMetadata.title')

        return (
            <Layout location={this.props.location} >
                <Helmet title={`About Ben Counsell | Web Developer in Stockholm`}>
                    <meta name="description" content="I’ve been making things for the web since inline styles and tables were in fashion." />
                </Helmet>
                <div className="flex flex-wrap mt-24">
                    <article className="article">
                        <header className="article-header">
                            <Heading>
                                Hello! I’m Ben,
                            </Heading>
                            <Paragraph>
                                an 🇬🇧 Englishman in 🇸🇪 Stockholm who makes things for the 🌐web. ☕Coffee addict, 👨‍👩‍👦‍👦father, and ⚕️alternative health enthusiast.
                            </Paragraph>
                            <Paragraph>
                                I’ve been making things for the web since inline styles and tables were in fashion. I share articles about <Link to="/tags/css">#css</Link>, <Link to="/tags/javascript">#javascript</Link>, and self <Link to="/tags/improvement">#improvement</Link>; I'm on a continuous mission to improve myself, those closest to me, and my local environment.
                            </Paragraph>

                            <hr className="border-t-2 border-grey-lighter my-8"/>

                            <p className="text-2xl text-purple font-bold">
                                I specialise in full-stack web development with a strong leaning towards CSS, JS, UX, and UI interactions.
                            </p>
                            <Paragraph>
                                Most recently, I built a subscription based marketplace &amp; video tutorial platform. The app has grown to 20k active users.
                            </Paragraph>

                            <Paragraph>It features a custom built admin dashboard, predictive search, super fast front-end using JS components, activity feeds, App Store-like approval process, REST API, and much more.</Paragraph>

                            <Paragraph>I am most comfortable with front-end development, however, my skillset includes back-end development with Laravel/PHP, database design, product management, e-commerce payment integrations, and email marketing.</Paragraph>

                            <div className="flex flex-wrap">
                                <div className="mr-24">
                                    <h4 className="text-red uppercase mt-4 mb-2 tracking-tight">Front-end Stack</h4>
                                    <ul className="mb-6">
                                        <li>React &amp; Vue.js</li>
                                        <li>Utility based CSS</li>
                                        <li>SASS / POSTCSS</li>
                                        <li>Webpack</li>
                                    </ul>
                                </div>

                                <div>
                                    <h4 className="text-red uppercase mt-4 mb-2 tracking-tight">Back-end stack</h4>
                                    <ul className="mb-6">
                                        <li>Laravel / PHP 7.2</li>
                                        <li>MySQL</li>
                                        <li>Nginx</li>
                                        <li>Digital Ocean</li>
                                    </ul>
                                </div>
                            </div>

                            <hr className="border-t-2 border-grey-lighter my-8" />

                            <p className="text-2xl text-purple font-bold">
                                I have 15+ years experience managing and building high quality apps used by millions of users worldwide.
                            </p>

                            <Paragraph className="mb-8">
                                I love shipping products, nothing beats that feeling of setting something live for everyone to use. Here are a handful of projects I'm particularly proud of.
                            </Paragraph>

                            <ul className="list-reset flex flex-col">
                                <li>
                                    <h3 className="text-3xl mb-0 leading-tight">RapidWeaver</h3>
                                    <p className="mb-4"><a href="https://realmacsoftware.com/rapidweaver">realmacsoftware.com</a></p>
                                    <p className="mb-2 leading-normal">RapidWeaver is the best web builder for the Mac and has been in development for over 15 years (I've been working on it for 13 of those yeards).</p>
                                    <ul className="ml-4 mb-12">
                                        <li>Themeing system design</li>
                                        <li>Web API integration</li>
                                        <li>Theme design & development</li>
                                        <li>UX design</li>
                                    </ul>
                                </li>
                                <li>
                                    <h3 className="text-3xl mb-0 leading-tight">RapidWeaver Community</h3>
                                    <p className="mb-4"><a href="https://rapidweavercommunity.com">rapidweavercommunity.com</a></p>
                                    <p className="mb-2 leading-normal">Full-stack development of a subscription based marketplace and video training platform.</p>
                                    <ul className="ml-4 mb-12">
                                        <li>Project management</li>
                                        <li>Vue.js Components</li>
                                        <li>Instant search</li>
                                        <li>Payment API integration</li>
                                        <li>App Store submission process</li>
                                        <li>Reviews, likes, activity feeds, version history, and more</li>
                                        <li>Tutorial Video production; recording, editing, publishing</li>
                                        <li>Bootstrap 4</li>
                                        <li>Laravel / PHP 7.2</li>
                                    </ul>
                                </li>
                                <li>
                                    <h3 className="text-3xl mb-1 leading-tight">The RapidWeaver Show</h3>
                                    <p className="mb-4"><a href="https://therapidweavershow.com">therapidweavershow.com</a></p>
                                    <p className="mb-2 leading-normal">A weekly podcast featuring lighthearted web dev chat.</p>
                                    <ul className="ml-4 mb-12">
                                        <li>Weekly podcast discussing the latest web development trends</li>
                                        <li>Setup recording of all hosts and guests</li>
                                        <li>Audio editing and mixing</li>
                                        <li>iTunes submissions</li>
                                    </ul>
                                </li>
                            </ul>
                        </header>
                    </article>
                    <div>
                        <h4 className="text-red uppercase mt-4 mb-2 tracking-tight">Connect</h4>
                        <ul className="mb-12">
                            <li><a className="text-black" href="https://twitter.com/bencounsell">Twitter</a></li>
                            <li><a className="text-black" href="https://instagram.com/bencounsell">Instagram</a></li>
                            <li><a className="text-black" href="https://linkedin.com/in/bencounsell">LinkedIn</a></li>
                        </ul>

                        <h4 className="text-red uppercase mt-4 mb-2 tracking-tight">Key Skills</h4>
                        <ul className="mb-12">
                            <li>15+ years experience shipping web based products</li>
                            <li>Highly skilled full-stack developer</li>
                            <li>Focus on front-end, CSS &amp; UX</li>
                            <li>Sharing knowledge and teaching</li>
                            <li>Wide-ranging e-commerce experience</li>
                            <li>Product Management &amp; Goal Setting</li>
                        </ul>

                        <h4 className="text-red uppercase mt-4 mb-2 tracking-tight">I Like Working With</h4>
                        <ul className="">
                            <li>JS, CSS, and HTML</li>
                            <li>UX &amp; UI interaction</li>
                            <li>Accessibility</li>
                            <li>Performance</li>
                        </ul>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default About

export const pageQuery = graphql`
  query AboutQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
